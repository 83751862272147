<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-info-card
        :title="title"
        color="primary"
      >
        <slot />
      </base-info-card>

      <template v-for="({ icon, text,ref, title: t }, i) in business">
        <base-avatar-card
          :key="i"
          :icon="icon"
          :outlined="false"
          :title="!dense ? t : undefined"
          color="transparent"
          horizontal
          space="0"
        >
          <!-- Do not use v-html for user -->
          <!-- provided values -->
          <!-- <div v-html="text" /> -->
          <a
            :href="ref"
            style="text-decoration: none;"
            class="mb-8 grey--text body-1 d-block text-none"
          >
            <div v-html="text" />
          </a>
        </base-avatar-card>

        <v-divider
          v-if="i + 1 !== business.length"
          :key="`divider-${i}`"
          class="my-2"
        />
      </template>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseBusinessContact',

    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
    },

    data: () => ({
      business: [
        /* {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        }, */
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '+91 6383906364',
          // text: '01 (800) 433 744<br>01 (800) 433 633',
          ref: 'tel:+916383906364',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'hello@hi5magic.com',
          // text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
          ref: 'mailto:hello@hi5magic.com?subject=Design%20Theme%20Queries',
        },
      ],
    }),
  }
</script>
