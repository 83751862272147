<template>
  <v-theme-provider :dark="dark">
    <div>
      <base-img
        :src="require('@/assets/logo-hi5magic.png')"
        contain
        max-width="128"
        min-height="78"
        width="100%"
      />

      <base-title
        size="body-1"
        space="4"
        title="WE ARE HERE FOR YOU"
        weight="regular"
      />

      <base-body>
        Whether you are looking to promote your business, building a brand image or need help with social media posts we are here to help you. Message us directly or use the form to send your requirements. Our support team is top-notch, and we're here to answer any questions.
        <!-- Lorem ipsum dolor, sit amet consectetur adipisicing elit. Illo pariatur quidem, quis modi iure nihil commodi suscipit, corporis dicta adipisci, voluptatum quia cupiditate. Nulla minus voluptatum nostrum deleniti, asperiores facere. -->
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        outlined
        to="/about"
      >
        More Info
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        /* {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '8553 N. Beach St. Ste. 227<br>Fort Worth, Texas 76137',
        }, */
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: '(91) 6383906364',
          // text: '01 (800) 433 744<br>01 (800) 433 633',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'hi5magic@gmail.com<br>support@mayicheckin.com',
          // text: 'john@vuetifyjs.com<br>heather@vuetifyjs.com',
        },
      ],
    }),
  }
</script>
