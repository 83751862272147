<template>
  <v-container class="pa-0">
    <v-hover v-slot="{ hover }">
      <v-card
        class="mx-auto"
        color="light-blue lighten-3"
        max-width="500"
      >
        <base-img
          :src="require(`@/assets/${ imagename }`)"
          width="500"
          height="250"
        >
          <v-expand-transition>
            <div
              v-if="hover"
              style="height: 500px;"
            >
              <v-overlay
                value="true"
                absolute
                opacity="0.8"
                color="rgba(5, 11, 31, .8)"
              >
                <base-title
                  :title="title"
                  class="text-uppercase"
                  space="1"
                />
              </v-overlay>
            </div>
          </v-expand-transition>
        </base-img>
        <v-card-text
          class="pt-6"
          style="position: relative;"
        >
          <base-title
            v-if="subtitle"
            :title="subtitle"
          />
          <v-btn
            absolute
            :color=color
            class="white--text"
            fab
            large
            right
            top
            :href="text"
          >
            <base-icon>
              {{ icon }}
            </base-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </v-hover>
  </v-container>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'BaseHover',

    mixins: [Heading],

    props: {
      dark: Boolean,
      color: {
        type: String,
        default: 'primary',
      },
      icon: String,
      text: String,
      imagename: String,
      title: String,
      subtitle: String,
    },
  }
</script>
