import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'

import VueGtag from 'vue-gtag'
import VueTypedJs from 'vue-typed-js'

Vue.use(
  VueTypedJs,
  VueGtag,
  {
    config: { id: 'G-0H165VFH05' },
  },
  router,
)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
